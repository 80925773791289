<template>
  <div>
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div class="search_input">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 210px; border-right: 1px #dcdfe6 solid"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入手机号查询"
          v-model="searchPhone"
          style="width: 300px"
        ></el-input>
        <i
          class="icon-sousuo iconfont"
          @click="searchList"
          style="cursor: pointer"
        ></i>
      </div>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#edecec', color: '#4b4b4b' }"
      >
        <el-table-column prop="authName" label="姓名" width="160">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="160">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="210">
        </el-table-column>
        <el-table-column prop="driverLicenseImg" label="驾驶证" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.frontImg"
              :preview-src-list="[scope.row.frontImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleLicenseImg" label="行驶证" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.vehicleLicenseImg"
              :preview-src-list="[scope.row.vehicleLicenseImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="holdImg"
          label="手持驾驶证的人车合照"
          width="180"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.holdImg"
              :preview-src-list="[scope.row.holdImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="driverLicenseSubmitTime"
          label="用户提交时间"
          width="200"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleReset(scope.row)"
              >重置审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrlInternation } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "车主认证",
        },
        {
          name: "审核拒绝列表",
        },
      ], // 面包屑数据

      searchPhone: "",

      valueArea: "",
      options: [],

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.options = areaCodeData;

    showLoading();
    const opt = {
      url: reqUrlInternation.getDriverAuditingList,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: "",
        auditStatus: 3,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          res.data.data.list.map((item) => this.changeItem(item));
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          hideLoading();
        }
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrlInternation.getDriverAuditingList,
        method: "POST",
        params: JSON.stringify({
          phone: this.searchPhone,
          phoneArea: this.valueArea,
          auditStatus: 3,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleReset(row) {
      this.$confirm("您确定重置审核结果吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrlInternation.resetOverseasDriver,
            method: "POST",
            params: JSON.stringify({
              id: row.id,
              userId: row.userId,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "重置成功!",
                });
                this.refresh();

                hideLoading();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    changeItem(item) {
      item.driverLicenseSubmitTime = format_time_date(
        item.driverLicenseSubmitTime
      );
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrlInternation.getDriverAuditingList,
        method: "POST",
        params: JSON.stringify({
          phone: this.searchPhone,
          phoneArea: this.valueArea,
          auditStatus: 3,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .search_input {
    border: 1px solid #dcdfe6;
    display: flex;
    border-radius: 30px;
    background: #fff;
    line-height: 40px;
    padding-right: 20px;

    ::v-deep .el-input {
      .el-input__inner {
        border: none;
        border-radius: 30px;
      }
    }
  }
}
</style>
